<template>
  <v-dialog
    v-model="hideShowDialog"
    scrollable
    width="600"
    eager
    persistent
    @keydown.esc="closeHideShow"
  >
    <template #activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" small class="mr-1" v-on="on">
        <v-icon class="mr-2" small>fa-plus</v-icon> Add To Project
      </v-btn>
    </template>
    <v-card :loading="isLoading">
      <v-card-title>Add Cameras To Project</v-card-title>
      <v-divider></v-divider>
      <ValidationObserver v-slot="{ invalid, passes }" ref="addCamerasForm">
        <v-card-text class="pt-5">
          <ECol cols="auto" sm="12" md="12" class="pa-0">
            <v-form>
              <!-- CAMERAS  -->
              <Autocomplete
                v-model="selectedCameras"
                item-value="id"
                item-text="name"
                label="Cameras"
                multiple
                chips
                small-chips
                deletable-chips
                rules="required"
                :list-items="camerasItems"
                resource="cameras"
              />
              <!-- PROJECT  -->
              <Autocomplete
                v-model="selectedProject"
                resource="projects"
                item-value="id"
                item-text="name"
                label="Project"
                clearable
                is-combobox
                rules="required"
              />
            </v-form>
          </ECol>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeHideShow">Close</v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="passes(addToProject)"
            >Add</v-btn
          >
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  components: {
    Autocomplete,
  },
  props: {
    cameras: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      selectedOwner: {
        email: "construction@evercam.io",
        fullname: "Construction Evercam",
        id: 13959,
      },
      isLoading: false,
      hideShowDialog: false,
      selectedCameras: [],
      camerasItems: [],
      selectedProject: null,
      cameraExids: [],
    }
  },
  computed: {
    ...mapStores(useReportStore),
  },
  watch: {
    hideShowDialog(visible) {
      if (visible) {
        this.selectedCameras = this.cameras
        this.camerasItems = this.cameras
      }
    },
  },
  methods: {
    clearForm() {
      this.selectedCameras = []
      this.selectedProject = null
      this.cameraExids = []
      this.$refs.addCamerasForm.reset()
    },
    async addToProject() {
      console.log("selectedProject", this.selectedProject)
      this.isLoading = true
      if (!this.selectedProject?.id) {
        await this.addToNewProject()
      } else {
        await this.addToExistingProject()
      }
      this.isLoading = false
      this.closeHideShow()
    },
    async addToNewProject() {
      try {
        await AdminApi.projects.createProject({
          ownerId: this.selectedOwner.id,
          projectName: this.selectedProject,
          cameras: this.selectedCameras.map((c) => c.exid),
        })

        this.$notifications.success(
          `The project "${this.selectedProject}" has been created successfully`
        )
        this.reportStore.searchFilters = {}
      } catch (error) {
        this.$notifications.error({
          text: `The project "${this.selectedProject}" could not be created`,
          error,
        })
      }
    },
    async addToExistingProject() {
      try {
        this.selectedCameras.forEach((c) => {
          this.cameraExids.push(c.exid)
        })
        await AdminApi.projects.addToProject(this.selectedProject?.exid, {
          cameraExids: this.cameraExids,
        })
        this.$notifications.success(
          `Cameras added to "${this.selectedProject.name}" project successfully`
        )
        this.reportStore.searchFilters = {}
        this.$emit("update-cameras")
      } catch (error) {
        this.$notifications.error({
          text: `Cameras could not be added to "${this.selectedProject.name}"  project!`,
          error,
        })
      }
    },
    closeHideShow() {
      this.clearForm()
      this.hideShowDialog = false
    },
  },
}
</script>
